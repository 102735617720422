import React from 'react';
import {
  Box,
  AlertColor,
  Typography,
  Card,
  CardContent,
  Grid,
  useMediaQuery,
  useTheme,
} from '@mui/material';
import AlertIcon from './AlertIcon';
import ShowIf from '../ConditionalRender/ShowIf';

type MessageType = {
  title: string;
  main: string;
  bottomParagraph?: string;
};

type AlertMessageProps = {
  severity: AlertColor;
  message: MessageType;
  installerName?: string;
};

const severityColors: Record<AlertColor, string> = {
  success: '#00A86B',
  warning: '#FFCC00',
  error: '#FF3333',
  info: '#007BFF',
};

const AlertMessage = ({
  severity,
  message,
  installerName,
}: AlertMessageProps) => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  const color = severityColors['success'];
  const severityIcon = ['error', 'warning'].includes(severity)
    ? 'warning'
    : 'success';

  return (
    <Card
      sx={{
        border: `2px solid ${color}`,
        borderRadius: '8px',
        maxWidth: '100%',
        boxShadow: 'none',
      }}
    >
      <CardContent
        sx={{
          paddingBottom: '0',
          '&:last-child': { paddingBottom: '16px', paddingRight: '24px' },
        }}
      >
        <Grid container display={'flex'}>
          {!isMobile && (
            <Grid item>
              <Box
                display="flex"
                alignItems="flex-start"
                justifyContent="center"
                height="100%"
                paddingTop="7px"
              >
                <Box
                  sx={{
                    borderRadius: '50%',
                    width: '30px',
                    height: '30px',
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'flex-start',
                    marginRight: '20px',
                    marginLeft: '10px',
                  }}
                >
                  <AlertIcon
                    alertType={severityIcon}
                    iconColor={color}
                    sx={{ color: 'success', fontSize: '30px' }}
                  />
                </Box>
              </Box>
            </Grid>
          )}
          <Grid item xs>
            <Box>
              <Box
                display="flex"
                alignItems="center"
                justifyContent="space-between"
              >
                <Box display="flex" alignItems="center" gap={1}>
                  {isMobile && (
                    <Box
                      sx={{
                        borderRadius: '50%',
                        width: '30px',
                        height: '30px',
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                      }}
                    >
                      <AlertIcon
                        alertType={severityIcon}
                        iconColor={color}
                        sx={{
                          color: 'white',
                          fontSize: '30px',
                        }}
                      />
                    </Box>
                  )}
                  <Typography
                    component="div"
                    sx={{
                      fontSize: '18px',
                      fontWeight: 500,
                      paddingBottom: isMobile ? '4px' : '0',
                    }}
                  >
                    {message.title +
                      (isMobile
                        ? '!'
                        : installerName && severity === 'success'
                        ? `, ${installerName}!`
                        : '!')}
                  </Typography>
                </Box>
              </Box>
            </Box>
            <Typography
              sx={{
                fontSize: '14px',
              }}
            >
              {message.main}
            </Typography>
            <ShowIf every={[!!message.bottomParagraph]}>
              <Box display="flex" flexDirection="column" gap={1} marginTop={1}>
                <Box
                  display="flex"
                  flexDirection="column"
                  gap={1}
                  width={isMobile ? 0.8 : 'fit-content'}
                >
                  <Box
                    display="flex"
                    justifyContent="space-between"
                    gap={'4rem'}
                  >
                    <Typography
                      sx={{
                        fontWeight: 700,
                        color: 'red',
                      }}
                    >
                      {'< 50kW'}
                    </Typography>
                    <Typography
                      sx={{
                        fontWeight: 700,
                        color: 'red',
                      }}
                    >
                      {'$5.75/w'}
                    </Typography>
                  </Box>
                  <Box display="flex" justifyContent="space-between">
                    <Typography
                      sx={{
                        fontWeight: 700,
                        color: '#e56910',
                      }}
                    >
                      {'50-100kW'}
                    </Typography>
                    <Typography
                      sx={{
                        fontWeight: 700,
                        color: '#e56910',
                      }}
                    >
                      {'$4.75/w'}
                    </Typography>
                  </Box>
                  <Box display="flex" justifyContent="space-between">
                    <Typography
                      sx={{
                        fontWeight: 700,
                        color: '#FEA363',
                      }}
                    >
                      {'100-250kW'}
                    </Typography>
                    <Typography
                      sx={{
                        fontWeight: 700,
                        color: '#FEA363',
                      }}
                    >
                      {'$4.50/w'}
                    </Typography>
                  </Box>
                  <Box display="flex" justifyContent="space-between">
                    <Typography
                      sx={{
                        fontWeight: 700,
                        color: '#22A06B',
                      }}
                    >
                      {'> 250kW'}
                    </Typography>
                    <Typography
                      sx={{
                        fontWeight: 700,
                        color: '#22A06B',
                      }}
                    >
                      {'$4.00/w'}
                    </Typography>
                  </Box>
                </Box>
                <Typography
                  sx={{
                    fontSize: '14px',
                  }}
                >
                  {message.bottomParagraph}
                </Typography>
              </Box>
            </ShowIf>
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  );
};

export default AlertMessage;
